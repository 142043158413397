<div class="flex justify-center flex-col mb-3">
  <h1 class="font-black text-[20px] text-black">Configurações de Templates de mensagens de Whatsapp</h1>
  <h6 class="text-zinc-400 font-light text-sm my-2">Gerencie os templates de mensagens de WhatsApp</h6>
</div>

<div
  class="w-full max-h-[calc(100vh-240px)] flex flex-wrap gap-[12px]"
  *ngIf="templates.length > 0"
>
  <div class="w-full max-h-[calc(100vh-240px)] flex flex-wrap gap-[12px]">
    <div class="inline-block min-w-full py-2 align-middle">
      <div
        class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg bg-white border-gray-100 border-2"
      >
        <table class="min-w-full divide-gray-100 divide-y-[3px]">
          <thead class="bg-gray-50">
            <tr>
              <th
                scope="col"
                class="px-3 py-3.5 text-left text-sm font-light text-slate-500"
              >
                Nome do modelo
              </th>
              <th
                scope="col"
                class="px-3 py-3.5 text-left text-sm font-light text-slate-500"
              >
                Preview do modelo
              </th>
              <th
                scope="col"
                class="py-3.5 pl-4 pr-3 text-left text-sm font-light text-slate-500"
              >
                Categoria
              </th>
              <th
                scope="col"
                class="px-3 py-3.5 text-left text-sm font-light text-slate-500"
              >
                Idioma
              </th>
              <th
                scope="col"
                class="px-3 py-3.5 text-left text-sm font-light text-slate-500"
              >
                Status
              </th>
              <th
                *ngIf="false"
                scope="col"
                class="px-3 py-3.5 text-right text-sm font-light text-slate-500"
              >
                Ações
              </th>
            </tr>
          </thead>
          <tbody class="divide-gray-100 divide-y-[3px] bg-white">
            <tr
              class="rounded-none cursor-pointer"
              *ngFor="let template of templates; let i = index"
              (click)="showEditTemplate(template.id)"
            >
              <td class="whitespace-nowrap px-3 py-4">
                <div class="flex gap-2">
                  <div class="flex flex-col">
                    <div class="font-weight-600">
                      {{ template.name}}
                    </div>
                  </div>
                </div>
              </td>
              <td class="whitespace-nowrap px-3 py-4">
                <div class="flex gap-2">
                  <div class="flex flex-col">
                    <div class="font-weight-600">
                      {{ previewArray[i].text | truncate: 50 }}
                    </div>
                  </div>
                </div>
              </td>
              <td class="whitespace-nowrap px-3 py-4">
                <div class="flex gap-2">
                  <div class="flex flex-col">
                    <div class="font-weight-600">
                      {{ dictionaryCategory(template.category) }}
                    </div>
                  </div>
                </div>
              </td>
              <td class="whitespace-nowrap px-3 py-4">
                {{ dictionaryLanguage(template.language) }}
              </td>
              <td class="whitespace-nowrap px-3 py-4">
                <div class="flex gap-2">
                  <div class="flex flex-col">
                    <div class="font-weight-600">
                      {{ dictionaryStatus(template.status) }}
                    </div>
                  </div>
                </div>
              </td>
              <td
                class="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6"
                *ngIf="false"
              >
                <div class="inline-block text-left" x-data="{ menu: false }">
                  <button
                    x-on:click="menu = ! menu; $event.stopPropagation();"
                    type="button"
                    class="flex items-center text-gray-400 hover:text-gray-600 focus:outline-none"
                    id="menu-button"
                    aria-expanded="true"
                    aria-haspopup="true"
                  >
                    <span class="sr-only"></span>
                    <svg
                      class="h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"
                      />
                    </svg>
                  </button>
                  <div
                    x-show="menu"
                    x-on:click.away="menu = false"
                    class="origin-top-right absolute right-20 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="menu-button"
                    tabindex="-1"
                  >
                    <div class="" role="none">
                      <a
                        href="javascript:void(0)"
                        class="text-red-600 font-medium hover:text-gray-900 hover:bg-gray-50 block px-4 py-2 text-sm"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0"
                        (click)="
                          showEditTemplate(template.id); $event.stopPropagation()
                        "
                      >
                        Editar
                      </a>
                    </div>
                    <div class="" role="none">
                      <a
                        href="javascript:void(0)"
                        class="text-red-600 font-medium hover:text-gray-900 hover:bg-gray-50 block px-4 py-2 text-sm"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0"
                        (click)="deleteTemplate(template.id); $event.stopPropagation()"
                      >
                        Remover
                      </a>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <paginator *ngIf="pager.total > pager.perPage" class="flex justify-content-center mt-3 mb-3" [pager]="pager"
          (onPager)="loadPage($event)">
        </paginator>
      </div>
    </div>
  </div>
</div>

<empty-records-component
  *ngIf="templates.length === 0"
  [message]="'Nenhum resultado foi encontrado.'"
  [subMessage]="
    'Tente ajustar o filtro ou redefinir para ver todos os resultados.'
  "
>
</empty-records-component>