import { Component, Injector, Input, OnInit } from "@angular/core";
import { TemplateMessageObject, TemplateRequest, TrendCloudAPIService } from "@trendbuild/trend-cloud-api";
import { AbstractComponent, Channel, Pager, WaTemplateResponse } from "lib-trend-core";
import { Observable } from "rxjs";

@Component({
  selector: 'setting-template-list-component',
  templateUrl: './setting-template-list.component.html',
})

export class SettingTemplateList extends AbstractComponent implements OnInit {
  constructor(injector: Injector) {
    super(injector);
  }

  @Input() channel: Channel

  private trendCloudAPIService: TrendCloudAPIService = new TrendCloudAPIService()
  templates: WaTemplateResponse[] = [];
  pager: Pager<WaTemplateResponse> = new Pager<WaTemplateResponse>({ perPage: 19 });
  listObservable: Observable<Pager<WaTemplateResponse>>;
  previewArray: Array<{text: string; type: string, example?: object}>

  ngOnInit(): void {
    this.pager = {
      page: 1,
      perPage: 10,
      previousPage: 0,
      total: 0,
      list: []
    };

    this.getList()
  }

  loadPage(page: number) {
    this.pager.page = page;
    this.getList();
  }

  dictionaryLanguage(language: string) {
    const dictionary = {
      "pt_BR": "Português",
      "pt_PT": "Português",
      "en_US": "Inglês",
      "en": "Inglês",
    }

    return dictionary[language]
  }

  dictionaryStatus(status: string) {
    const dictionary = {
      "APPROVED": "Aprovado",
    }

    return dictionary[status]
  }

  dictionaryCategory(category: string) {
    const dictionary = {
      "MARKETING": "Marketing",
      "UTILITY": "Utilitário"
    }

    return dictionary[category]
  }

  async getList() {
    const templateRequest: TemplateRequest = {
      token: this.channel.metadata.token,
      whatsAppBusinessAccountID: +this.channel.metadata.whatsAppBusinessAccountID,
      phoneNumberID: +this.channel.metadata.phoneNumberID,
      fields: [],
      limit: 10,
      status: '',
    };

    const response = await this.trendCloudAPIService.getTemplates(templateRequest)

    const allTemplates = (response as any).data || [];

    this.pager.total = (response as any).total || allTemplates.length;

    const start = (this.pager.page - 1) * this.pager.perPage;
    const end = start + this.pager.perPage;

    this.templates = allTemplates.slice(start, end);

    this.previewArray = this.templates.map(template => template.components.filter(component => component.type === 'BODY')[0])

  }

  showEditTemplate(idTemplate: string) {
  }

  deleteTemplate(idTemplate: string) {
  }
}